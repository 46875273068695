import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------
// const axios = require('axios');


export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const[Emailerror,setEmailError]= useState("")
  const[PasswordError,setPasswordError]=useState("")
  const [values, setvalues] = useState({

    email: "",
    password: ""
})

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(8, 'Password length must be atleast 8')
    .max(30, 'Password is too long to handle'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };



  const methods = useForm({
    // resolver: yupResolver(RegisterSchema),
    // defaultValues,
    // values,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
  // navigate('/dashboard', { replace: true });
    console.log("Value are",values)
  
    try {
      axios.post(`https://chatbot.digitallandscape.com.pk:5028/admin_login`, values)
          .then( (response)=> {
              // console.log('Response : ',JSON.stringify(response))
              // console.log('admin_id_token : ',response.data?.admin_id_token)
              if (response?.status === 200 && response?.data.admin_id_token) {
                const name=values.email.replace(/^(.+)@(.+)$/g,'$1')
                const adminDetails={
                  admin_id_token:response.data?.admin_id_token,
                  admin_name:name,
                  admin_email:values.email
                }
                  // localStorage.setItem('admin_id_token', response.data?.admin_id_token)
                  localStorage.setItem('adminDetails',JSON.stringify(adminDetails))
                  console.log("Response is",response)
                  navigate(`/dashboard`)
              }
              else{
                console.log("Email is not admin")
                console.log("Response is",response)
                if(response.data.message==="EMAIL_NOT_FOUND"){
                    setEmailError("Email you entered is not ADMIN")
                }
                else if(response.data.message==="INVALID_PASSWORD"){
                  setPasswordError("Password is incorrect.")
                }
              
              }
          })
          .catch((error) =>{
              console.log(error);
              alert(error.message)
          });
  } catch (error) {
      console.log('Error : ', error)
      alert(JSON.stringify(error))
  }
  }




  const onChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
};


const errorInput={
  marginTop: "2px",
  fontSize: "12px",
  color: "#dc3545",
  marginLeft: "8px"
}
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack> */}

        <RHFTextField name="email" label="Email address" onChange={onChange} type="email"/>
        <span className="error-input" style={errorInput}>
                  {Emailerror}
                </span>
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
<span className="error-input" style={errorInput}>
                  {PasswordError}
                </span>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
