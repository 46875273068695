import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import axios, * as others from 'axios';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

function SessionsScreen() {
  const navigate = useNavigate();

  // let { id } = useParams()

  const styles = useStyles();

  const [chatList, setChatList] = useState([]);
  const userId = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    getUserChat();
  }, []);

  const getUserChat = () => {
    const Admintoken = JSON.parse(localStorage.getItem('adminDetails'));
    const token = Admintoken.admin_id_token;
    const id = localStorage.getItem('chat_id');

    const session = localStorage.getItem('session');

    const data = {
      admin_id_token: token,
      user_id: userId.user_id,
    };
    // // console.log('Data from id', data);
    try {
      axios
        .post(`https://chatbot.digitallandscape.com.pk:5028/get_user_sessions`, data)
        .then((response) => {
          // // console.log(response);
          if (response?.status === 200) {
            if (response.data?.is_valid_token === true) {
              setChatList(response.data.data);
            } else {
              localStorage.setItem('adminDetails', ' ');
              // navigate('../LoginScreen')
              navigate(`/login`);
            }
          }
        })
        .catch((error) => {
          // // console.log(error);
          alert(error.message);
          navigate(`../users`);
        });
    } catch (error) {
      // // console.log('Error : ', error);
      alert(JSON.stringify(error));
    }
  };

  const logout = () => {
    localStorage.setItem('admin_id_token', '');
    navigate('../LoginScreen');
  };

  const backtohome = () => {
    // localStorage.setItem('admin_id_token','')
    navigate(`../HomeScreen`);
  };
  const navigateToChatScreen = (item) => {
    localStorage.setItem('session', item);
    // return <SessionsScreen id={item} />
    navigate(`../chats`);
    // navigate(`../Sessionscreen`)
    // navigate(`../ChatScreen/${item}`)
  };

  // // console.log('Chatlist is', chatList);
  const listItems = chatList.map((item, index) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    // <li key={index} className={styles.listItem} onClick={() => {
    // navigateToChatScreen(item)
    // }}>

    <Button
      variant="contained"
      component={RouterLink}
      to="../chats"
      startIcon={<Iconify icon="eva:reverse-fill" />}
      key={index}
      className={styles.sessionsBtn}
      onClick={() => {
        navigateToChatScreen(item);
      }}
    >
      {item}
    </Button>
  ));

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
           User Sessions
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/users"
            startIcon={<Iconify icon="akar-icons:arrow-back-thick" />}
          >
            Back to Users
          </Button>
        </Stack>
        <Stack>
          <div>
            <div>
              <table className={styles.table}>
                <tr>
                  <td className={styles.tableHeading}>User Name</td>
                  <td className={styles.tableData}>{userId.name.toUpperCase()}</td>
                </tr>
                <tr>
                  <td className={styles.tableHeading}>Age</td>
                  <td className={styles.tableData}>{userId.age}</td>
                </tr>
                <tr>
                  <td className={styles.tableHeading}>Martial Status</td>
                  <td className={styles.tableData}>{userId.marital_staus.toUpperCase()}</td>
                </tr>
                <tr>
                  <td className={styles.tableHeading}>Diagnosis</td>
                  <td className={styles.tableData}>{userId.diagnosis.toUpperCase()}</td>
                </tr>
              </table>
            </div>
            <Stack>
              <div>
                <Typography variant="h5" gutterBottom>
                  Sessions Details
                </Typography>
                {listItems}
              </div>
            </Stack>
          </div>
        </Stack>
      </Container>
    </Page>
  );
}

const useStyles = createUseStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  header: {
    fontSize: '4vh',
    marginLeft: '2vw',
  },
  sessionsBtn: {
    display: 'inline-block',
    margin: '5px 10px 5px 10px',
    height: '50px',
    textAlign: 'center',
    padding: '10px 20px 10px 20px',
    background: '#67B3FF 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 12px #0000001F',
    borderRadius: '28px 28px 28px 28px',
    opacity: 1,
  },
  list: {
    width: '100%',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: 10,
    padding: 0,
    cursor: 'pointer',
    marginTop: '10px',
    webkitBoxShadow: '1px 4px 4px #00000050',
    mozBoxShadow: '1px 4px 4px #00000050',
    boxShadow: '1px 4px 4px #00000050',
  },

  table: {
    bordercollapse: 'collapse',
    width: '100%',
    marginTop: '5px',
  },
  tableHeading: {
    border: '1px solid #dddddd',
    textalign: 'left',
    color: '#FFFFFF',
    // padding: '8px',
    backgroundColor: '#4da6ff',
    borderRadius: '8px',
    fontSize: '12pt',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    padding: '10px 10px 10px 10px',
    margin: '10px 10px 10px 10px',
    webkitBoxShadow: '1px 4px 4px #00000050',
    mozBoxShadow: '1px 4px 4px #00000050',
    boxShadow: '1px 4px 4px #00000050',
    transition: 'all 0.5s ease-in-out',
    '&:-moz-transition': 'all 0.5s ease-in-out',
    '&:-webkit-transition': 'all 0.5s ease-in-out',
    '&:-o-transition': 'all 0.5s ease-in-out',
    '&:hover': {
      transition: 'all 0.5s ease-in-out',
      '&:-moz-transition': 'all 0.5s ease-in-out',
      '&:-webkit-transition': 'all 0.5s ease-in-out',
      '&:-o-transition': 'all 0.5s ease-in-out',
      backgroundColor: '#80bfff',
    },
  },
  tableData: {
    border: '1px solid #dddddd',
    textalign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#4da6ff',
    borderRadius: '8px',
    fontSize: '12pt',
    fontFamily: 'sans-serif',
    padding: '10px 10px 10px 10px',
    margin: '10px 10px 10px 10px',
    webkitBoxShadow: '1px 4px 4px #00000050',
    mozBoxShadow: '1px 4px 4px #00000050',
    boxShadow: '1px 4px 4px #00000050',
    transition: 'all 0.5s ease-in-out',
    '&:-moz-transition': 'all 0.5s ease-in-out',
    '&:-webkit-transition': 'all 0.5s ease-in-out',
    '&:-o-transition': 'all 0.5s ease-in-out',
    '&:hover': {
      transition: 'all 0.5s ease-in-out',
      '&:-moz-transition': 'all 0.5s ease-in-out',
      '&:-webkit-transition': 'all 0.5s ease-in-out',
      '&:-o-transition': 'all 0.5s ease-in-out',
      backgroundColor: '#80bfff',
    },
  },
  logoutbtn: {
    backgroundColor: '#4CAF50' /* Green */,
    border: 'none',
    color: 'white',
    padding: '15px 32px',
    textalign: 'center',
    textdecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
  },
});

export default SessionsScreen;
